.ai-main-container {
  background-color: #d9d9d9;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 100%;
  flex: 1;
  align-items: center;
  text-align: justify;
}

.phrase-container {
  border-radius: 10px;
  margin: 10px;
  position: relative;
  display: flex;
  width: calc(100% - 20px);
  flex: 1;
  background-color: #ffffff;
}

.text-container {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.text-phrase {
  color: #000000;
  font-family: "Sitka-Text", Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: fit-content;
  padding: 10px;
}

.back-icon {
  display: block;
  height: 17px;
  position: absolute;
  top: 17px;
  left: 15px;
  width: 24px;
}

.response-container {
  background: linear-gradient(180deg, rgb(234, 78, 81) 0%, rgb(175, 24, 71) 100%);
  border-radius: 10px;
  position: relative;
  width: calc(100% - 20px);
  justify-content: center;
  flex: 1;
  align-items: center;
  display: flex;
}


.text-response {
  color: #ffffff;
  font-family: "Sitka-TextBold", Helvetica;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: justify;
  padding: 10px;
}

.triangle {
  margin-right: 100px;
  height: 33px;
  position: relative;
  width: 29px;

}

.logo {
  margin-left: 20px;
  margin-top: 30px;
  align-self: first baseline;
  height: 150px;
  position: relative;
  bottom: 0px;
  width: 200px;
}

.text-to-speech-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.animation {
  font-size: 24px;
  color: #00f;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}