.home-div {
    flex-direction: row;
    display: flex;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    height: 100vh;
}

.contenu-home {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.eugenia-pres {
    position: relative;
    display: flex;
    flex-direction: row;
}

.eugenia-pres1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    flex: 1;
    
}

.eugenia-pres2 {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}


.p {
    text-align: justify;
}

.button-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.img-cont {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.logo-home {
    height: 300px;
    width: 400px;
    bottom: 0;
    margin-bottom: -70px;
}

.guide-img {
    height: 400px;
    width: 500px;
    align-self: center;
    position: relative;
}
.button-home {
    background: linear-gradient(180deg, rgb(216, 42, 42) 0%, rgb(255, 83, 143) 100%);
    border-radius: 24px 24px 24px 24px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    padding: 20px;
    border: 0;
}

/** css de bande **/
.bande-perforee {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    flex: 1;
    background-image: url('back-home.svg');
    background-repeat: no-repeat;
    background-size: cover;   
}
.bande-perforee2{
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    flex: 1;
    background-image: url('back-home2.svg');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    
}

.butt-second {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 60px;
    position: relative;
    flex: 1;
    flex-direction: row;
    justify-content: center;
}

.button1 {
    background: linear-gradient(to right, #AB0B44, #FF4D4D); /* Fond du bouton */
    border-radius: 24px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 32px;
    font-size: 16px;
    border: none;
    position: relative;
    overflow: hidden; /* Cache les éléments qui dépassent pendant l'animation */
    transition: background 0.3s ease-in-out;
}

.button1 span {
    position: relative;
    display: flex;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    white-space: nowrap;
}

/* Apparence de la flèche lors du survol */
.button1::before {
    content: '→'; /* Flèche à la place du texte */
    position: absolute;
    left: 50%; /* Centrer horizontalement */
    transform: translateX(-50%); /* Ajuste la position pour que la flèche soit bien centrée */
    opacity: 0;  /* Flèche invisible au départ */
    font-size: 1.5em;
    color: white;
    transition: all 0.3s ease-in-out;
}

/* Lorsqu'on survole le bouton */
.button1:hover::before {
    opacity: 1; /* Flèche devient visible */
}

.button1:hover span {
    transform: translateX(50%); /* Le texte est déplacé vers la droite */
    opacity: 0; /* Le texte disparaît */
}

  



.contact-div {
    display: flex;
    background: linear-gradient(180deg, rgb(238, 63, 75) 0%, rgb(170.53, 11.01, 68.44) 100%);
    padding: 40px;
    flex-direction: row;
    gap: 40px;
    color: #ffffff;
    flex: 1;
}

.text-contact {
    display: flex;
    flex: 1;
    flex-direction: column;
}