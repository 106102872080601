.tools {
  background: linear-gradient(180deg, rgb(255, 76.5, 76.5) 0%, rgb(170.53, 11.01, 68.44) 100%);
  border-radius: 0px 24px 24px 24px;
  overflow: hidden;
  position: absolute;
  align-content: center;
  flex-direction: row;
  padding: 15px;
  justify-content: center;
  z-index: 2;
  display: flex;
  /*flex-wrap: wrap;*/
}


.tool {
  position: relative;
  margin: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tool-container {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.language-select {
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

.language-select:hover {
  background: #e0e0e0;
  border-radius: 5px;
}

.tooltip {
  visibility: hidden;
  background-color: rgba(106, 226, 235, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: visibility 0.2s, opacity 0.2s;
  opacity: 0;
}

.tool:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.action {
  width: 30px;
  margin: 5px;
  align-self: flex-start;
  position: relative;
  cursor: pointer;
}

.cercle-container {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.cercle {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  background: linear-gradient(180deg, rgb(255, 76.5, 76.5) 0%, rgb(170.53, 11.01, 68.44) 100%);
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
}

.cercle.expanded {
  transition: width 0.5s ease;
  display: flex;
  width: 110px;
  border-radius: 40px 40px 40px 40px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.icon {
  height: 7vh;
  width: 7vh;
}

.phrase {
  white-space: nowrap;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-decoration: solid;
}