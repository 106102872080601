.editor-main-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.editor-container {
    width: 50vw;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    height: 100%;
}

.pdf-container {
    background-color: gray;
    padding: 10px;
}

.pause {
    align-self: center;
    background: linear-gradient(180deg, rgb(255, 76.5, 76.5) 0%, rgb(170.53, 11.01, 68.44) 100%);
    border-radius: 50%;
    padding: 10px;
    color: aliceblue;
}