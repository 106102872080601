.quiz-initDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  flex: 1;
  padding: 80px;
}

.quiz-questionD {
  display: flex;
  background: linear-gradient(180deg, rgb(255, 76, 76) 0%, rgb(171, 11, 68) 100%);
  border-radius: 24px 24px 24px 0px;
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 10px;
  flex: 1;
  flex-direction: column;
}

.quiz-questionP {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  color: gray;
  border-radius: 16px 16px 16px 0px;
  justify-content: center;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
}

.quiz-responseA {
  flex: 1;
  display: flex;
  background:linear-gradient(180deg, rgb(255, 76, 76) 0%, rgb(171, 11, 68) 100%);;
  border-radius: 24px 24px 0px 24px;
  width: 100%;
  padding: 10px; 
  align-items: center;
  justify-content: center;
}

.quiz-response {
  flex: 1;
  border: 0px ;
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  justify-content: center;
  color: rgb(19, 17, 17);
  border-radius: 16px 16px 0px 16px;
  width: 100%;
  padding: 10px; 
  box-sizing: border-box; 
}

.correction-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.buttonCorrection {
  cursor: pointer;
  border: 0cap;
  display: flex;
  background: linear-gradient(180deg, rgb(255, 76, 76) 0%, rgb(171, 11, 68) 100%);
  border-radius: 24px 24px 24px 24px;
  color: white;
  padding: 12px;
  margin: 12px;
}

.buttonQCM {
  cursor: pointer;
  border: 0cap;
  display: flex;
  background-color: rgb(201, 194, 194);
  justify-content: center;
  text-align: center;
  border-radius: 24px 24px 24px 24px;
  color: rgb(112, 110, 110);
  padding: 12px;
  margin: 14px;
}
.buttonQCM:hover {
  background-color: rgb(170, 170, 170); 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  color: rgb(60, 60, 60); 
}

.quiz-responseQCM {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  border-radius: 24px 24px 0px 24px;
  width: 100%;
  padding: 10px;
  align-items: center;
}
.quiz-results{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
  flex: 1;
  padding: 80px;
}

.quiz-responses{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  flex: 1;
}
.correction-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-self: center;
}

.quiz-response {
  margin-bottom: 20px;
}
