.profile-main-container {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: start;
    height: 100vh;
}

.profile-main-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.profile-second-content {
    padding: 20px;
    text-align: center;
    background-color: #D9D9D9;
    flex-direction: column;
    overflow: auto;
    display: flex;
    border-radius: 10px;
}

.alert {
    padding: 10px;
    background-color: #f8d7da;
    /* Light red background for error alert */
    color: #721c24;
    /* Dark red text color for visibility */
    border: 1px solid #f5c6cb;
    /* Border to match the background */
    border-radius: 4px;
    margin: 10px 0;
    /* Space around the alert */
    font-size: 14px;
    /* Adjust text size */
    text-align: center;
    /* Center align the alert text */
    transition: all 0.3s ease-in-out;
    /* Smooth appearance/disappearance */
}