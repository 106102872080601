.quizNav {
    display: flex;
    border-radius: 0%;
    background-color: #D9D9D9;
    position: relative;
    justify-content: start;
    flex-direction: column;
    padding: 40px;
}

.second {
    position: relative;
    justify-content: center;
    align-self: center;
}

.lien {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    gap: 8px;
    position: inherit;
}

.text .tooltiptext {
    visibility: hidden;
    max-width: fit-content;

    background-color: #403f3f;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    font-size: 12px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.text .tooltiptext {
    top: -5px;
    left: 105%;
}

.text:hover .tooltiptext {
    visibility: visible;
}


.text {
    font-size: 30px;
    font-weight: 700;
    font-family: "Sitara-Bold", Helvetica
}

.image {
    height: 39px;
    width: 34px;
}

.logoo {
    position: absolute;
    bottom: 0;
    margin-left: 80px;
    z-index: 1;
}