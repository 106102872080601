.import-file {
    border: 5px solid #D9D9D9;
    border-radius: 15px;
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.page-main-container {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}