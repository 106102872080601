.hobby-item {
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: border 0.3s, background-color 0.3s;
}

.hobby-item:hover {
    background-color: #e0e0e0;
}

.hobby-item.selected {
    border: 2px solid #FF4D4D;
    background-color: #fcd4d4;
}