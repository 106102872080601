.main-container-main-div {
    max-height: fit-content;
    max-width: fit-content;
    background: linear-gradient(to right, #AB0B44, #FF4D4D);
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.main-container-secondary-div {
    padding: 20px;
    max-height: fit-content;
    max-width: fit-content;
    display: flex;
    background-color: white;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}