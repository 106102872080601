@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/OpenDyslexic-Regular.otf') format('opentype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/OpenDyslexic-Bold.otf') format('opentype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/OpenDyslexic-BoldItalic.otf') format('opentype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/OpenDyslexic-Italic.otf') format('opentype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica-Bold.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica-BoldOblique.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica-Oblique.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/calibri-italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/calibri-regular.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/calibri-bold-italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/calibri-bold.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comic Sans';
  src: url('./assets/fonts/Comic\ Sans\ MS.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiresias';
  src: url('./assets/fonts/Tiresias_Infofont.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiresias';
  src: url('./assets/fonts/Tiresias_Infofont_Italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sassoon Primary';
  src: url('./assets/fonts/Sassoon-Primary.otf') format('opentype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('./assets/fonts/centurygothic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('./assets/fonts/centurygothic_bold.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('./assets/fonts/Andika-Regular.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('./assets/fonts/Andika-Bold.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('./assets/fonts/Andika-Italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Andika';
  src: url('./assets/fonts/Andika-BoldItalic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('./assets/fonts/LSANS.TTF') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('./assets/fonts/LSANSDI.TTF') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('./assets/fonts/LSANSD.TTF') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('./assets/fonts/LSANSI.TTF') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url('./assets/fonts/PTSans-Regular.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('./assets/fonts/PTSans-BoldItalic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url('./assets/fonts/PTSans-Bold.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('./assets/fonts/PTSans-Italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoeuithis.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoeuithisz.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoeuithibd.ttf') format('truetype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoeuithisi.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('./assets/fonts/trebuc.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('./assets/fonts/Trebuchet-MS-Italic.ttf') format('truetype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill\ Sans.otf') format('opentype');
  /* Use the correct path */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill\ Sans\ Bold\ Italic.otf') format('opentype');
  /* Use the correct path */
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill\ Sans\ Bold.otf') format('opentype');
  /* Use the correct path */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill\ Sans\ Italic.otf') format('opentype');
  /* Use the correct path */
  font-weight: normal;
  font-style: italic;
}


.ql-font-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.ql-font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.ql-font-lato {
  font-family: 'Lato', sans-serif;
}

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-font-georgia {
  font-family: 'Georgia', sans-serif;
}

.ql-font-gill-sans {
  font-family: 'Gill Sans', sans-serif;
}

.ql-font-trebuchet-ms {
  font-family: 'Trebuchet MS', sans-serif;
}

.ql-font-segoe-ui {
  font-family: 'Segoe UI', sans-serif;
}

.ql-font-pt-sans {
  font-family: 'PT Sans', sans-serif;
}

.ql-font-lucida-sans {
  font-family: 'Lucida Sans', sans-serif;
}

.ql-font-andika {
  font-family: 'Andika', sans-serif;
}

.ql-font-century-gothic {
  font-family: 'Century Gothic', sans-serif;
}

.ql-font-sassoon-primary {
  font-family: 'Sassoon Primary', sans-serif;
}

.ql-font-tiresias {
  font-family: 'Tiresias', sans-serif;
}

.ql-font-comic-sans {
  font-family: 'Comic Sans', sans-serif;
}

.ql-font-calibri {
  font-family: 'Calibri', sans-serif;
}


.ql-font-arial {
  font-family: 'Arial', sans-serif;
}

.ql-font-times-new-roman {
  font-family: 'Times New Roman', sans-serif;
}

.ql-font-helvetica {
  font-family: 'Helvetica', sans-serif;
}

.ql-font-open-dyslexic {
  font-family: 'OpenDyslexic', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}