.hobbies-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}



p {
    margin-top: 15px;
}

.signup-text {
    padding-bottom: 5px;
    padding-top: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
}