.toolbar-main-container {
    background: linear-gradient(to right, #AB0B44, #FF4D4D);
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: start;
    flex-direction: column;
    gap: "10px";
    max-width: fit-content;
}

.separator-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.toolbar-separator {
    height: 2px;
    background: linear-gradient(to right, rgba(0, 128, 128, 0), white, rgba(0, 128, 128, 0));
    width: 100%;

}

.toolbar-element-main-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
}

.toolbar-element-sub-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 10px
}

.toolbar-element-logo {
    padding-top: 5px;
    justify-self: center;
}

.toolbar-element-text {
    max-width: fit-content;
    color: white;
    font-weight: bold;
}