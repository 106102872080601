html,
body {
  height: 100vh;
  margin: 0;
  font-family: 'Roboto Mono', monospace;
}

.Quiz {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.quizz {
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 100%;
}

.question {
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  background-repeat: no-repeat;
  background-image: url('./back.png');
  background-size: cover;
  overflow: scroll;
  
}

.setType-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  flex: 1;
  padding: 100px;
  font-family: "Sitara-Bold", Helvetica;
  color: #403f3f;
  ;
}

.typeRes {
  display: flex;
  background: linear-gradient(180deg, rgb(255, 76, 76) 0%, rgb(171, 11, 68) 100%);
  border-radius: 24px 24px 24px 24px;
  position: relative;
  justify-content: center;
  padding: 10px;
  flex: 1;
  color: rgb(231, 230, 230);
  cursor: pointer;
}

.typeRes:hover {
  background: linear-gradient(180deg, rgb(255, 100, 100) 0%, rgb(191, 11, 68) 100%); 
  transform: scale(1.05); 
}