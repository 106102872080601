.login-main-container {
    flex-direction: row;
    display: flex;
    flex: 1;
    align-items: center;

}

.login-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    height: 100vh;
    flex: 1;
}

.login-title {
    font-size: large;
    text-align: start;
    display: flex;
    font-size: 30px;
    width: 100%;
    font-family: '"Sitka-SmallBold", Helvetica';
    font-weight: bold;
    padding-bottom: 15px;
    color: #636363;
}

.login-text {
    font-family: '"Sitka-SmallBold", Helvetica';
}