.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.warning-box {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    max-width: 80%;
}

p {
    margin: 0 0 20px;
    font-size: 1.2em;
}

.buttons {
    display: flex;
    justify-content: space-around;
}

.cancel-button,
.proceed-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background: #ccc;
    color: #333;
}

.proceed-button {
    background: #007bff;
    color: #fff;
}