html,
body {
  height: 100vh;
  margin: 0;
  font-family: 'Roboto Mono', monospace;
}

.documents-main-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  height: 100vh;
}

.alert {
  padding: 10px;
  background-color: #f8d7da;
  /* Light red background for error alert */
  color: #721c24;
  /* Dark red text color for visibility */
  border: 1px solid #f5c6cb;
  /* Border to match the background */
  border-radius: 4px;
  margin: 10px 0;
  /* Space around the alert */
  font-size: 14px;
  /* Adjust text size */
  text-align: center;
  /* Center align the alert text */
  transition: all 0.3s ease-in-out;
  /* Smooth appearance/disappearance */
}